import React from 'react'

const Statistics = () => {
  return (
    <div
      style={{
        width: '94%',
        minHeight: 1024,
        margin: '33px auto auto'
      }}
    ></div>
  )
}

export default Statistics
