import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import { HashLink } from 'react-router-hash-link'

const StatisticSection = () => {
  return (
    <Paper
      variant='outlined'
      style={{
        width: '35%',
        height: '100%',
        borderColor: 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          padding: '14px 20px 0px 25px'
        }}
      >
        <Typography variant='subtitle2' style={{ color: '#000' }}>
          日成交额
        </Typography>
        <HashLink to='/statistics/#'>
          <Typography variant='button' color='primary'>
            查看全部
          </Typography>
        </HashLink>
      </div>
    </Paper>
  )
}

export default StatisticSection
